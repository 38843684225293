import * as React from 'react';
import styled from 'styled-components';
import { logos } from '@allenai/varnish/components';

export default class About extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <h3>The model</h3>
                <p>
                    The ProofWriter model reads in a new rulebase of natural language facts and
                    rules, and decides the truth value of new statements under open world
                    assumptions (True/False/Unknown). It builds on the 
                    earlier <a href="https://rule-reasoning.apps.allenai.org/">RuleTaker</a> work.
                </p>
                <p>
                    When applicable, the model can generate a proof detailing what facts and rules
                    are combined to reach a conclusion. If a statement cannot be proven, the model
                    instead looks for single facts (other than the statement itself) that can be
                    added to the theory to make the statement true.
                </p>
                <p>
                    The model can also generate all true implications of a theory (leave the
                    question statement blank).
                </p>
                <p>
                    See our paper{' '}
                    <a href="https://arxiv.org/abs/2012.13048">
                        <i>
                            "ProofWriter: Generating Implications, Proofs, and Abductive Statements
                            over Natural Language"
                        </i>
                    </a> for more details.
                </p>
                <h3>The dataset</h3>
                <p>
                    The model was trained on a dataset of synthetic language rules, augmented with
                    some crowdsourced data. For more details and to download the original dataset
                    see <a href="https://allenai.org/data/proofwriter">here</a>.
                </p>
                <p>
                    The model in this version of the demo is trained starting from a pretrained{' '}
                    <a href="https://github.com/google-research/text-to-text-transfer-transformer">
                        T5-large
                    </a>{' '}
                    model, a generative text-to-text transformer, fine-tuned on a combination of the
                    three tasks: proof generation, abductive reasoning, and implication enumeration.
                </p>
                <p>
                    <b>Note:</b> the best results in the paper used the much larger <b>T5-11B</b> model trained on each
                    task <b>individually</b>.
                </p>
                <p>
                    A project from the{' '}
                    <a href="https://allenai.org/aristo/">
                        <CenterAlign>
                            <logos.Aristo />
                        </CenterAlign>
                        Aristo Team
                    </a>
                    .
                </p>
            </React.Fragment>
        );
    }
}

const CenterAlign = styled.span`
    vertical-align: middle;
`;
