export interface ExampleGroup {
    title: string;
    examples: ExampleQuestion[];
}

export interface ExampleQuestion {
    description: string;
    passage: string;
    question: string;
}

export const exampleGroups: ExampleGroup[] = [
    {
        title: 'Conductivity and counterfactuals',
        examples: [
            {
                description:
                    'Nails are made of iron, which is a metal, which conducts electricity. Ask: Nails conduct electricity.',
                passage:
                    'Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is metal. \nNails are made of iron.',
                question: 'Nails conduct electricity.',
            },
            {
                description:
                    'Now suppose nails were made of plastic. Ask: Nails conduct electricity.',
                passage:
                    'Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is metal. \nNails are made of plastic. \nPlastic is an insulator.',
                question: 'Nails conduct electricity.',
            },
            {
                description:
                    'Now suppose nails were made of plastic AND plastic is a metal. Ask: Nails conduct electricity.',
                passage:
                    'Metals conduct electricity. \nInsulators do not conduct electricity. \nIf something is made of iron then it is a metal. \nNails are made of plastic. \nPlastic is a metal.',
                question: 'Nails conduct electricity.',
            },
        ],
    },
    {
        title: 'Harry Potter (imaginary worlds)',
        examples: [
            {
                description:
                    'Harry can do magic, and if you can do magic you can vanish. Ask: Harry can vanish.',
                passage:
                    'Harry can do magic. \nMuggles cannot do magic. \nIf a person can do magic then they can vanish. \nIf a person cannot do magic then they cannot vanish. \nMr Dursley is a Muggle.',
                question: 'Harry can vanish.',
            },
            {
                description:
                    'Harry can do magic, and if you can do magic you can vanish. Ask: Mr Dursley can vanish.',
                passage:
                    'Harry can do magic. \nMuggles cannot do magic. \nIf a person can do magic then they can vanish. \nIf a person cannot do magic then they cannot vanish. \nMr Dursley is a Muggle.',
                question: 'Mr Dursley can vanish.',
            },
        ],
    },
    {
        title: 'UK tax (adapted from www.aclweb.org/anthology/P19-1223.pdf)',
        examples: [
            {
                description:
                    "A few paraphrased statements of UK tax law. If you're French but have a UK civil service pension, do you pay UK tax?",
                passage:
                    "If someone is not a UK resident and they do not have a UK civil service pension then they do not pay UK pension tax. \nIf someone has a UK civil service pension then they pay pension tax in the UK. \nIf someone is a UK resident then they pay pension tax in the UK. \nIf someone's home country is UK then they are a UK resident. \nIf someone's home country is France then they are a French resident. \nJohn's home country is UK. \nPierre's home country is France. \nAlan's home country is France. \nAlan has a UK civil service pension.",
                question: 'Alan pays UK pension tax.',
            },
        ],
    },
    {
        title: 'Ethical rules',
        examples: [
            {
                description:
                    "If you take something you shouldn't, then you are stealing... Ask: John was doing a bad thing.",
                passage:
                    "If you take something you shouldn't, then you are stealing.\nIf you are stealing, then you are doing a bad thing.\nIf you take something that doesn't belong to you, then you are taking something you shouldn't.\nJohn took a book that didn't belong to him.",
                question: 'John was doing a bad thing.',
            },
        ],
    },
    {
        title: 'Examples from the paper: The Birds Rulebase',
        examples: [
            {
                description: "Ostriches and wounded birds can't fly. Can Bill the ostrich fly?",
                passage:
                    'Arthur is a bird. \nArthur is not wounded. \nBill is an ostrich. \nColin is a bird. \nColin is wounded. \nDave is not an ostrich. \nDave is wounded. \nIf someone is an ostrich then they are a bird. \nIf someone is an ostrich then they are abnormal. \nIf someone is an ostrich then they cannot fly. \nIf someone is a bird and wounded then they are abnormal. \nIf someone is wounded then they cannot fly. \nIf someone is a bird and not abnormal then they can fly.',
                question: 'Bill can fly.',
            },
            {
                description:
                    "Now suppose birds typically don't fly, but ostriches can fly. Can Bill the ostrich now fly?",
                passage:
                    'If someone is an ostrich then they can fly. \nIf someone is an ostrich then they are a bird. \nIf someone is a bird and not abnormal then they cannot fly. \nIf someone can fly then they are abnormal. \nArthur is a bird. \nBill is an ostrich.',
                question: 'Bill can fly.',
            },
        ],
    },
    {
        title: 'Examples from the paper: Electricity rulebase 1',
        examples: [
            {
                description:
                    'If an electrical circuit has a switch and a bell, and the switch is on, will the bell ring?',
                passage:
                    'The circuit has a switch. \nThe circuit has a bell. \nThe switch is on. \nIf the circuit has the switch and the switch is on then the circuit is complete. \nIf the circuit does not have the switch then the circuit is complete. \nIf the circuit is complete and the circuit has the light bulb then the light bulb is glowing. \nIf the circuit is complete and the circuit has the bell then the bell is ringing. \nIf the circuit is complete and the circuit has the radio then the radio is playing.',
                question: 'The bell is ringing.',
            },
        ],
    },
    {
        title: 'Examples from the datasets (development partition)',
        examples: [
            {
                description:
                    'Attributes w/ negation (all implications): Fiona is round. All green things are rough... Ask: All implications',
                passage:
                    'Fiona is round.\nAll green things are rough.\nWhite things are not cold.\nIf Fiona is rough and Fiona is furry then Fiona is not big.\nIf Fiona is cold and Fiona is rough then Fiona is not furry.\nRound things are green.\nIf something is round and furry then it is not big.\nAll furry things are big.\nIf something is round and not green then it is not big.\nFiona is not big.\nFiona is cold.',
                question: '',
            },
            {
                description:
                    'Attributes w/ negation (generate proof): Fiona is round... Ask: Fiona is not furry.',
                passage:
                    'Fiona is round.\nAll green things are rough.\nWhite things are not cold.\nIf Fiona is rough and Fiona is furry then Fiona is not big.\nIf Fiona is cold and Fiona is rough then Fiona is not furry.\nRound things are green.\nIf something is round and furry then it is not big.\nAll furry things are big.\nIf something is round and not green then it is not big.\nFiona is not big.\nFiona is cold.',
                question: 'Fiona is not furry.',
            },
            {
                description:
                    'Relations w/ negation (all implications): The bald eagle chases the bear. If someone chases the bald eagle... Ask: All implications.',
                passage:
                    'The bald eagle chases the bear.\nThe bear needs the bald eagle.\nIf someone chases the bald eagle then they do not chase the bear.\nIf someone needs the bald eagle then the bald eagle eats the bear.\nIf someone needs the bear then the bear is red.\nIf someone eats the bear then they are cold.\nIf someone is cold then they are not kind.\nIf someone eats the bear and they are not cold then the bear is not round.',
                question: '',
            },
            {
                description:
                    'Relations w/ negation (generate proof): The bald eagle chases the bear... Ask: The bald eagle is not kind.',
                passage:
                    'The bald eagle chases the bear.\nThe bear needs the bald eagle.\nIf someone chases the bald eagle then they do not chase the bear.\nIf someone needs the bald eagle then the bald eagle eats the bear.\nIf someone needs the bear then the bear is red.\nIf someone eats the bear then they are cold.\nIf someone is cold then they are not kind.\nIf someone eats the bear and they are not cold then the bear is not round.',
                question: 'The bald eagle is not kind.',
            },
            {
                description:
                    'Relations w/ negation (make it true): The bald eagle chases the bear... Ask: The bear is red.',
                passage:
                    'The bald eagle chases the bear.\nThe bear needs the bald eagle.\nIf someone chases the bald eagle then they do not chase the bear.\nIf someone needs the bald eagle then the bald eagle eats the bear.\nIf someone needs the bear then the bear is red.\nIf someone eats the bear then they are cold.\nIf someone is cold then they are not kind.\nIf someone eats the bear and they are not cold then the bear is not round.',
                question: 'The bear is red.',
            },
        ],
    },
];
