/**
 * This file is meant for shared display components that you use throughout
 * your application.
 *
 * Components with a lot of logic, or those that are particularly complicated
 * should probably be put in their own file. This file is meant for the
 * re-usable, simple things used in a lot of different spots in your UI.
 */
import * as React from 'react';
import styled from 'styled-components';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';

import { Prediction } from '../api';

export const LightPaper = styled.div`
    max-width: ${({ theme }) => theme.breakpoints.lg};
    background: ${({ theme }) => theme.palette.background.info};
    padding: ${({ theme }) => theme.spacing.md};
    line-height: 1.6;
    border: ${({ theme }) => `solid 1px ${theme.palette.border.info}`};
    border-radius: ${({ theme }) => theme.shape.borderRadius.default};
`;

export const PredictionInfo: React.SFC<{ prediction: Prediction }> = ({ prediction }) => {
    if (prediction.inferences.length > 0) {
        return (
            <LightPaper>
                <b>ProofWriter implications: </b> {prediction.answer}
                <br />
                <ul>
                    {prediction.inferences.map((text) => (
                        <li>{text}</li>
                    ))}
                </ul>
            </LightPaper>
        );
    } else if (prediction.missing_facts.length > 0) {
        return (
            <LightPaper>
                <b>ProofWriter answer: </b> {prediction.answer}
                <br />
                <b>It would be true if any of these hold: </b>
                <br />
                <ul>
                    {prediction.missing_facts.map((text) => (
                        <li>{text}</li>
                    ))}
                </ul>
            </LightPaper>
        );
    } else {
        return (
            <LightPaper>
                <b>ProofWriter answer: </b> {prediction.answer}
                <br />
                <b>Proof (raw): </b> {prediction.proof}
                <br />
                <b>Proof (details):</b>
                <br />
                <div>
                    {prediction.proof_pretty_print.map((text) => (
                        <span>
                            &nbsp;&nbsp;{text}
                            <br />
                        </span>
                    ))}
                </div>
            </LightPaper>
        );
    }
};

export const Error: React.SFC<{ message: string }> = ({ message }) => (
    <ErrorGrid>
        <WarningOutlined />
        {message}
    </ErrorGrid>
);

const TwoColumnGrid = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;
`;

const ErrorGrid = styled(TwoColumnGrid)`
    color: ${({ theme }) => theme.palette.text.error.hex};
`;
