/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish/components';
import Menu from 'antd/es/menu';
import { Link } from '@allenai/varnish-react-router';
import proofWriterLogo from './components/logo-proofwriter.png';

import Home from './pages/Home';
import About from './pages/About';
import { AppRoute } from './AppRoute';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Demo',
        component: Home,
    },
    {
        path: '/about',
        label: 'About',
        component: About,
    },
];

export default class App extends React.PureComponent<RouteComponentProps> {
    render() {
        return (
            <BrowserRouter>
                <Route path="/">
                    <Layout bgcolor="white">
                        <Header>
                            <Header.Columns columns="auto 1fr auto">
                                <Header.Logo
                                    label={
                                        <Header.AppName>
                                            ProofWriter: Soft Reasoning over Language
                                        </Header.AppName>
                                    }>
                                    <SimpleLogo>
                                        <img
                                            src={proofWriterLogo}
                                            width="64"
                                            height="64"
                                            alt="ProofWriter-logo"
                                        />
                                    </SimpleLogo>
                                </Header.Logo>
                                <span />
                                <Header.MenuColumn>
                                    <Menu
                                        defaultSelectedKeys={[this.props.location.pathname]}
                                        mode="horizontal">
                                        {ROUTES.map(({ path, label }) => (
                                            <Menu.Item key={path}>
                                                <Link to={path}>{label}</Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </Header.MenuColumn>
                            </Header.Columns>
                        </Header>
                        <Content main>
                            {ROUTES.map(({ path, component }) => (
                                <Route key={path} path={path} exact component={component} />
                            ))}
                        </Content>
                        <Footer />
                    </Layout>
                </Route>
            </BrowserRouter>
        );
    }
}

const SimpleLogo = styled.div`
    width: 50px;
    height: 50px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
